<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="dashboardContainerWrap"
  >
    <div class="top-filters">
      <date-filter
        v-model="options.date"
        filled
        dense
        class="dateOptions"
        :custom="false"
        @key="(v) => (options.dateType = v)"
      />
    </div>
    <dashboard-slider-cards
      :is-loading="isLoading"
      :items="dashboardTags"
    />
    <!-- Sliding Cards -->
    <v-row>
      <v-col
        md="4"
        cols="12"
        class="cardCustomSection"
      >
        <div
          v-if="!isLoading"
          class="Vehicle-Telematic-chart"
        >
          <apex-area-charts
            :loading="isLoading"
            name="vehicle"
            :title="$t('speed')"
            :labels="chartTimeStamp"
            :value="seriesSpeed"
            :filter="options.date"
            :xaxis-text="$t('dateTime')"
            date-format="hh:mm:ss A"
            :yaxis="{ min: 0, max: getMax(seriesSpeed) }"
          />
          <apex-area-charts
            :loading="isLoading"
            name="vehicle"
            type="bar"
            :title="$t('distance')"
            :labels="distanceTimeLabel"
            :value="seriesDistance"
            :filter="options.date"
            :xaxis-type="'categories'"
            :xaxis-text="'Date'"
            date-format="DD-MMM"
            tooltip-date-format="DD-MMM-YYYY"
            :yaxis="{ min: 0, max: getMax(seriesDistance) }"
          />
        </div>
        <div
          v-else
          class="Vehicle-Telematic-chart"
        >
          <v-skeleton-loader
            type="card"
            class="ma-2"
            width="100%"
            height="100%"
          />
          <v-skeleton-loader
            type="card"
            class="ma-2"
            width="100%"
            height="100%"
          />
        </div>
      </v-col>
      <v-col
        md="4"
        cols="12"
        class="cardCustomSection"
      >
        <!-- v-if="!isLoading" -->
        <div class="map-section">
          <DashboardMap
            :loading="isLoading || isMap"
            :items="list?.complete_route_map"
            :vehicle-model-type="list?.vehicle_model_type"
            :status="list?.vehicle_status"
          />
        </div>
        <!-- <div
          v-else
          class="map-section"
        >
          <v-skeleton-loader
            type="image"
            width="100%"
            height="100%"
          />
        </div> -->
      </v-col>
      <v-col
        md="4"
        cols="12"
        class="cardCustomSection"
      >
        <div
          v-if="!isLoading"
          class="card-section"
        >
          <div
            v-if="list.vehicle_tech == 'ice'"
            class="card-blue clickable"
            @click="openActiveModal('fuel')"
          >
            <div class="iconBox">
              <Icons name="fuel-level-icon" />
            </div>
            <span class="text-muted">Fuel Level</span>
            <div class="valueBox">
              {{
                list.latest_vehicle_trip_telematicData
                  ? list.latest_vehicle_trip_telematicData[0].fuel_level
                  : "N/A"
              }}
              {{
                list.latest_vehicle_trip_telematicData &&
                  list.latest_vehicle_trip_telematicData[0].fuel_level == "N/A"
                  ? ""
                  : "L"
              }}
            </div>
          </div>
          <div
            v-else-if="list.vehicle_tech == 'ev'"
            class="card-yellow clickable"
            @click="openActiveModal('soc')"
          >
            <div class="iconBox">
              <Icons name="lightning-icon" />
            </div>
            <span class="text-muted">SOC Level</span>
            <div class="valueBox">
              {{
                list.latest_vehicle_trip_telematicData
                  ? list.latest_vehicle_trip_telematicData[0].state_of_charge
                  : "N/A"
              }}
              {{
                list.latest_vehicle_trip_telematicData &&
                  list.latest_vehicle_trip_telematicData[0].state_of_charge ==
                  "N/A"
                  ? ""
                  : "%"
              }}
            </div>
          </div>
          <div
            class="card-green clickable"
            @click="openActiveModal('strength')"
          >
            <div class="iconBox">
              <Icons name="range" />
            </div>
            <span class="text-muted">Signal Strength</span>
            <div class="valueBox">
              {{
                list.latest_vehicle_trip_telematicData
                  ? list.latest_vehicle_trip_telematicData[0].signal_strength
                  : "N/A"
              }}{{
                list.latest_vehicle_trip_telematicData &&
                  list.latest_vehicle_trip_telematicData[0].signal_strength ==
                  "N/A"
                  ? ""
                  : "%"
              }}
            </div>
          </div>
          <div
            class="card-red clickable"
            @click="openActiveModal('latency')"
          >
            <div class="iconBox">
              <Icons name="time-left-icon" />
            </div>
            <span class="text-muted">Latency</span>
            <div class="valueBox">
              {{
                list.latest_vehicle_trip_telematicData
                  ? list.latest_vehicle_trip_telematicData[0].latency
                  : "N/A"
              }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="card-section"
        >
          <div
            v-for="n in 3"
            :key="n"
          >
            <v-skeleton-loader
              type="card"
              class="mb-4"
              width="100%"
              height="165px"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <Modal
      v-if="isActiveModal"
      title=""
      :is-visible="isActiveModal"
      is-view
      width="800px"
      :toggle="() => (isActiveModal = false)"
    >
      <apex-area-charts
        v-if="list?.vehicle_tech == 'ev'"
        :loading="isLoading"
        name="vehicle"
        title="State Of Charge"
        :labels="chartTimeStamp"
        :value="seriesSOC"
        :filter="options.date"
        :xaxis-text="$t('dateTime')"
        date-format="DD-MMM hh:mm A"
        :yaxis="{ min: 0, max: 100 }"
        unit="%"
      />

      <apex-area-charts
        v-if="list?.vehicle_tech == 'ice'"
        :loading="isLoading"
        name="vehicle"
        title="Fuel Level"
        :labels="chartTimeStamp"
        :value="seriesFuel"
        :filter="options.date"
        :xaxis-text="$t('dateTime')"
        date-format="DD-MMM  hh:mm A"
        :yaxis="{ min: 0, max: getMax(seriesFuel) }"
        unit="L"
      />
    </Modal>

    <Modal
      v-if="isLatencyModal"
      title=""
      :is-visible="isLatencyModal"
      is-view
      width="800px"
      :toggle="() => (isLatencyModal = false)"
    >
      <apex-charts
        :loading="isLoading"
        name="vehicle"
        title="Latency"
        :labels="chartTimeStamp"
        :value="seriesLatency"
        :filter="options.date"
        :xaxis-text="$t('dateTime')"
        date-format="DD-MMM hh:mm A"
        :yaxis="{ min: 0, max: 100 }"
        unit="s"
        type="line"
      />
    </Modal>
    <Modal
      v-if="isStrengthModal"
      title=""
      :is-visible="isStrengthModal"
      is-view
      width="800px"
      :toggle="() => (isStrengthModal = false)"
    >
      <apex-area-charts
        :loading="isLoading"
        name="vehicle"
        title="Signal Strength"
        :labels="chartTimeStamp"
        :value="seriesSignalStrength"
        :filter="options.date"
        :xaxis-text="$t('dateTime')"
        date-format="DD-MMM  hh:mm A"
        :yaxis="{ min: 0, max: 100 }"
        unit="%"
      />
    </Modal>
  </v-container>
</template>
<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: {
    DateFilter: () => import("@/components/base/DateDropDown.vue"),
    DashboardSliderCards: () =>
      import("@/components/sections/DashboardSliderCards.vue"),
    ApexAreaCharts: () => import("@/components/sections/ApexAreaChart.vue"),
    // ApexBarCharts: () => import("@/components/sections/ApexBarChart.vue"),
    ApexCharts: () => import("@/components/sections/ApexChart.vue"),
    DashboardMap: () => import("../utils/DashboardMap.vue"),
    Modal: () => import("@/components/Modals/Modal.vue"),
    Icons: () => import("@/components/base/icons.vue"),
  },
  props: {
    name: {
      type: String,
      default() {
        return "";
      },
    },
    selectedTab: {
      type: String,
      default() {
        return "";
      },
    },
    optionFilters: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isMap: false,
      loading: false,
      isActiveModal: false,
      isStrengthModal: false,
      isLatencyModal: false,
      options: {},
      lastVehicleID: null,
      loopInterval: 15,
      chartTimeStamp: [],
      seriesSOC: [],
      seriesFuel: [],
      seriesLatency: [],
      seriesSignalStrength: [],
      seriesSpeed: [],
      distanceTimeLabel: [],
      seriesDistance: [],
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "telematicDetails/getIsLoadingTelematicDetails",
      list: "telematicDetails/getTelematicsChart",
    }),
    dashboardTags() {
      const arr = [
        {
          myIcon: "destination-icon",
          name: "total_distance_travelled",
          value: this.list.total_distance_travelled,
          unit: "(KM)",
        },
        {
          myIcon: "total-average-speed",
          name: "average_speed",
          value: this.list.total_avg_speed,
          unit: "(KM/Hr)",
        },
        {
          myIcon: "total-duration-icon",
          name: "average_trip_duration",
          value: this.list.total_avg_duration,
          unit: "",
        },
        {
          myIcon: "duration-icon",
          name: "total_trip_duration",
          value: this.list.total_duration,
          unit: "",
        },
        {
          myIcon: "ev-distance-efficiency",
          name: "all_trips",
          value: this.list.all_trips,
          unit: "",
        },
        {
          myIcon: "travel-icon",
          name: "active_trips",
          value: this.list.active_trips_count,
          unit: "",
        },
        {
          myIcon: "performance-icon",
          name: "active_trips_distance",
          value: this.list.active_trips_distance,
          unit: "(KM)",
        },
      ];
      return arr;
    },
  },
  watch: {
    "optionFilters.vehicle_id": {
      handler: async function () {
        if (this.name === this.selectedTab) {
          this.fetchData();
        }
      },
    },
    selectedTab: {
      handler: async function (v) {
        this.isMap = true;
        if (v === this.name) {
          setTimeout(() => (this.isMap = false), 0);
          const vd = this.optionFilters.vehicle_id;
          if (vd != this.lastVehicleID) this.fetchData();
        }
      },
    },
    "options.date": {
      handler: function () {
        this.fetchData();
      },
    },
  },
  mounted() {
    this.$store.dispatch("telematicDetails/setLoading", true);
  },
  methods: {
    openActiveModal(value) {
      if (value == "fuel" || value == "soc") {
        this.isActiveModal = true;
      } else if (value == "strength") {
        this.isStrengthModal = true;
      } else if (value == "latency") {
        this.isLatencyModal = true;
      }
    },
    fetchData: debounce(async function () {
      const params = {
        ...this.options,
        ...this.optionFilters,
      };
      this.lastVehicleID = this.optionFilters.vehicle_id;
      if (!this.optionFilters.vehicle_id) return 0;
      await this.$store
        .dispatch("telematicDetails/setTelematicCharts", params)
        .then(() => {
          this.setChartData(this.list);
        });
    }, 500),
    setChartData(data) {
      let d = data?.vehicle_trip_telematicData;
      const timeLabel = [];
      const fuel = [];
      const soc = [];
      const speed = [];
      const letency = [];
      const strength = [];

      d.forEach((el, i) => {
        timeLabel.push(el.date_time_gmt_05);
        if (i == 0) {
          const d = new Date(el.date_time_gmt_05);
          d.setMinutes(0);
          d.setSeconds(0);
          speed.push({ x: d, y: el.speed });
        }
        soc.push({ x: new Date(el.date_time_gmt_05), y: el.state_of_charge });
        fuel.push({ x: new Date(el.date_time_gmt_05), y: el.fuel1 });
        speed.push({ x: new Date(el.date_time_gmt_05), y: el.speed });
        strength.push({
          x: new Date(el.date_time_gmt_05),
          y: el.signal_strength,
        });
        letency.push({ x: new Date(el.date_time_gmt_05), y: el.latency });
      });

      this.chartTimeStamp = timeLabel;
      this.seriesSOC = soc;
      this.seriesFuel = fuel;
      this.seriesSpeed = speed;
      this.seriesSignalStrength = strength;
      this.seriesLatency = letency;
      let dis = data?.last_seven_days_distance;
      const distanceLabel = [];
      const distanceSeries = [];
      dis.forEach((el) => {
        distanceLabel.push(el.trip_date);
        distanceSeries.push({
          x: el.trip_date,
          y: el.total_distance,
        });
      });

      this.distanceTimeLabel = distanceLabel;
      this.seriesDistance = distanceSeries;
    },
    getMax(val) {
      const arr = val.map((r) => parseInt(r.y));
      const sr = Math.max(...arr);
      return sr > 10 ? sr : 10;
    },
  },
};
</script>
<style lang="sass" scoped>
.content-wrapper
  display: flex
  gap: 1rem
  > div
    flex: 1

.Vehicle-Telematic-chart
  display: flex
  justify-content: space-between
  flex-direction: column
  gap: 1rem
  div
    flex: 1

  > div
    background: #fff
    padding: 1rem
.map-section
  background: #fff
  padding: 1rem

.card-section
  user-select: none
  display: flex
  flex-wrap: wrap
  gap: 0rem
  >div
    background: #fff
    // flex-basis: 49%
    //flex: 1
    width: 32%
    padding: 1.5rem
    display: flex
    justify-content: space-between
    flex-direction: column
    margin: 0 2% 0 0
    &:last-child
      margin: 0 0 0 0

.card-section div
  .iconBox
    width: fit-content !important
    border-radius: 5px
    padding: 0.3rem
    i
      font-size: 3rem
  span
    padding-top: 1rem
    font-size: 14px
  .valueBox
    font-size: 1rem
    font-weight: 600


$blueColor : #5D87FF
$blueBG : #ECF2FF

$yellowColor:  #FFAE20
$yellowBG:  #FEF5E5

$greenColor:  #13DEB9
$greenBG:  #D7FFE9

$redColor:  #FA896B
$redBG:  #FDEDE8
// Card color
.card-section
  .card-blue
    .iconBox
      background: $blueBG
      width: 3.25rem !important
      height: 2.75rem
      padding: 0 !important
      :deep .lg span svg
        fill: $blueColor
        width: 2.6rem !important
        height: 2.6rem !important
        margin: 0 0 0 4px
        path
          fill: $blueColor
    .valueBox
      color: $blueColor

  .card-yellow
    .iconBox
      background: $yellowBG
      width: 3.25rem !important
      height: 2.75rem
      padding: 0 !important
      :deep .lg span svg
        fill: $yellowColor
        width: 4.5rem
        height: 4.5rem
        margin: -1.125rem 0 0 -0.4375rem
        path
          fill: $yellowColor
    .valueBox
      color: $yellowColor

  .card-green
    .iconBox
      background: $greenBG
      width: 3.25rem !important
      height: 2.75rem
      padding: 0 !important
      :deep .lg span svg
        fill: $greenColor
        width: 4.5rem !important
        height: 4.5rem !important
        margin: -14px 0px 0 0 !important
        path
          fill: $greenColor
    .valueBox
      color: $greenColor

  .card-red
    .iconBox
      background: $redBG
      width: 3.25rem !important
      height: 2.75rem
      padding: 0 !important
      :deep .lg span svg
        fill: $redColor
        width: 4.5rem !important
        height: 4.5rem !important
        margin: -1.125rem 0 0 0rem
        path
          fill: $redColor
    .valueBox
      color: $redColor
// Card color

.clickable
  cursor: pointer
  transition: all 0.6s ease
  &:hover
    background:  #dddddd


:deep .dateOptions
  .v-card
    padding: 0.5rem
    padding-bottom: 0 !important

:deep .destination-icon, :deep .duration-icon, :deep .total-duration-icon
  width: 4.2rem !important

.cardCustomSection
  padding: 6px
.dashboardContainerWrap
  padding: 12px 0
@media (min-width: 1900px)
  .cardCustomSection
    padding: 12px
@media only screen and (max-width: 960px)
  .content-wrapper, .Vehicle-Telematic-chart
    flex-direction: column
  .card-section > div
    flex-basis: 49%
</style>
